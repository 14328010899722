import React, { useEffect, useState } from "react";
import BannerBgIMg from "../../../../assets/images/homeBg.png";
import BannerImg from "../../../../assets/images/Home3d.png";
import CartImg from "../../../../assets/icons/bag.png";
import { PrimaryBtn, SecondaryBtn } from "../../../../components/btns/Btns";
import "./landing.css";

import TruckIco from "../../../../assets/icons/truck.png";
import HPhoneIco from "../../../../assets/icons/headphone.png";
import WalletIco from "../../../../assets/icons/wallet.png";
import MoneyIco from "../../../../assets/icons/moneys.png";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../utils/firebase";
import { COLLECTION_NAMES } from "../../../../utils/constants";
const features = [
  {
    icon: TruckIco,
    title: "Free Shipping",
    subTitle: "Free delivery for all orders",
    bgColor: "#F5F5FE"
  },
  {
    icon: HPhoneIco,
    title: "24/7 Support",
    subTitle: "Friendly 24/7 support",
    bgColor: "#FFF9F2"
  },
  {
    icon: MoneyIco,
    title: "Secure Payment",
    subTitle: "All cards accepted",
    bgColor: "#FFF7FB"
  }
  // { icon: WalletIco, title: 'Money Guarantee', subTitle: '30 days money back', bgColor: '#FAF5FD' },
];
function Landing() {
  const [trendingData, setTrendingData] = useState({});
  const [loading, setLoading] = useState(true);
  const getTrendingData = async () => {
    const docRef = doc(db, COLLECTION_NAMES.configs, "TRENDING_PRODUCT");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setTrendingData(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    setLoading(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getTrendingData();
  }, []);
  return (
    <div className="landing">
      <div
        className="banner"
        style={{ backgroundImage: `url(${BannerBgIMg})` }}
      >
        <div className="txt_wrpr">
          <div style={{ width: "80%" }}>
            <p className="tag">#Trending product</p>
            <h1>{trendingData?.title}</h1>
            <p>{trendingData?.desc}</p>
            <div className="btn_wrpr">
              <PrimaryBtn
                onClick={() => navigate("/product/" + trendingData.product)}
                title="View Product"
              />
              {/* <SecondaryBtn icon={CartImg} /> */}
            </div>
          </div>
        </div>
        <div className="img_wrpr">
          <img src={trendingData?.image} alt="Picture" />
        </div>
      </div>
      <div className="feature_wpr">
        {features.map((ftr) => (
          <div className="feature" style={{ backgroundColor: ftr.bgColor }}>
            <img src={ftr.icon} />
            <h2>{ftr.title}</h2>
            <p>{ftr.subTitle}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Landing;
