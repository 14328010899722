// import React from "react";

// const HelpCenter = () => {
//   return (
//     <div
//       style={{
//         fontFamily: "Arial, sans-serif",
//         lineHeight: "1.6",
//         padding: "20px",
//         color: "#333",
//         minHeight: "70vh"
//       }}
//     >
//       <h1 style={{ color: "#2c3e50", fontSize: "2.5em", textAlign: "start" }}>
//         Help & Support
//       </h1>
//       <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
//         We're here to help! If you have any questions or need assistance, please
//         don't hesitate to reach out to us through any of the following channels:
//       </p>

//       <div style={{ display: "flex", rowGap : 20,columnGap:50, alignItems: "center" ,flexWrap:'wrap'}}>
//         <div style={{ fontSize: "1.2em", marginTop: "20px" }}>
//           {/* <h2 style={{ color: '#2c3e50', fontSize: '2em' }}>Contact Us</h2> */}
//           <p>
//             <strong>Email:</strong>{" "}
//             <a href="mailto:support@bricado.com" style={{ color: "#3498db" }}>
//               support@bricado.com
//             </a>
//           </p>
//           <p>
//             <strong>Phone:</strong>{" "}
//             <a href="tel:+1234567890" style={{ color: "#3498db" }}>
//               +1 (234) 567-890
//             </a>
//           </p>
//         </div>

//         <div style={{ fontSize: "1.2em", marginTop: "20px" }}>
//           {/* <h2 style={{ color: '#2c3e50', fontSize: '2em' }}>Follow Us</h2> */}
//           <p>
//             <strong>Instagram:</strong>{" "}
//             <a
//               href="https://instagram.com/bricado"
//               style={{ color: "#3498db" }}
//             >
//               @bricado
//             </a>
//           </p>
//           <p>
//             <strong>Facebook:</strong>{" "}
//             <a href="https://facebook.com/bricado" style={{ color: "#3498db" }}>
//               facebook.com/bricado
//             </a>
//           </p>
//           <p>
//             <strong>WhatsApp:</strong>{" "}
//             <a href="https://wa.me/1234567890" style={{ color: "#3498db" }}>
//               Chat with us on WhatsApp
//             </a>
//           </p>
//         </div>
//       </div>

//       <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
//         Thank you for choosing Bricado. We are committed to providing you with
//         the best service and support.
//       </p>
//     </div>
//   );
// };

// export default HelpCenter;

import React from "react";
import BricadoLogo from "../../assets/images/logos/bricado_logo_black.png";
import MailLogo from "../../assets/images/mail.png";
import InstaLogo from "../../assets/images/insta.png";
import CallLogo from "../../assets/images/call.png";
const HelpCenter = () => {
  const styles = {
    container: {
      // height: "80vh",
      display: "flex",
      alignItems: "center",
      padding: "20px",
      fontFamily: "Arial, sans-serif"
    },
    image: {
      width: "40%",
      marginRight: "20px",
      padding: 50
    },
    content: {
      marginLeft: "10%",
      width: "60%",
      height: "70%"
    },
    heading: {
      fontSize: "50px",
      fontWeight: "bold",
      marginBottom: "10px",
      // color:'orange',
      borderBottom: "10px solid pink"
    },
    paragraph: {
      fontSize: "16px",
      marginBottom: "20px",
      width: "80%",
      fontWeight: 500
    },
    contact: {
      marginTop: 5,
      display: "flex",
      alignItems: "center"
    },
    icon: {
      marginRight: "20px"
    },
    contactText: {
      fontSize: "16px"
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.image}>
        <img src={BricadoLogo} alt="About Us" style={{ width: "100%" }} />
      </div>
      <div style={styles.content}>
        <div style={styles.heading}>Help & Support</div>
        <div style={styles.paragraph}>
          <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            We're here to help! If you have any questions or need assistance,
            please don't hesitate to reach out to us through any of the
            following channels:
          </p>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={styles.contact}>
              <div style={styles.icon}>
                <img src={CallLogo} style={{ width: 20, height: 20 }} />
              </div>
              <div style={styles.contactText}> +91 7025 5751 12 </div>
            </div>
            <div style={styles.contact}>
              <div style={styles.icon}>
                <img src={MailLogo} style={{ width: 20, height: 20 }} />
              </div>
              <div style={styles.contactText}> helpdesk.bricado@gmail.com </div>
            </div>
            <div style={styles.contact}>
              <div style={styles.icon}>
                <img
                  src={InstaLogo}
                  style={{ width: 22, height: 22, objectFit: "contain" }}
                />
              </div>
              <div style={styles.contactText}>bricado_official</div>
            </div>
          </div>
          <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            Thank you for choosing Bricado. We look forward to serving you and
            helping you discover the best in quality and value.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
