import React from "react";
import BricadoLogo from "../../assets/images/logos/bricado_logo_black.png";
import MailLogo from "../../assets/images/mail.png";
import InstaLogo from "../../assets/images/insta.png";
import CallLogo from "../../assets/images/call.png";
const AboutUs = () => {
  const styles = {
    container: {
      // height: "80vh",
      display: "flex",
      alignItems: "center",
      padding: "20px",
      fontFamily: "Arial, sans-serif"
    },
    image: {
      width: "40%",
      marginRight: "20px",
      padding: 50
    },
    content: {
      marginLeft: "10%",
      width: "60%",
      height: "70%"
    },
    heading: {
      fontSize: "50px",
      fontWeight: "bold",
      marginBottom: "10px",
      // color:'orange',
      borderBottom: "10px solid pink"
    },
    paragraph: {
      fontSize: "16px",
      marginBottom: "20px",
      width: "80%",
      fontWeight: 500
    },
    contact: {
      marginTop: 5,
      display: "flex",
      alignItems: "center"
    },
    icon: {
      marginRight: "20px"
    },
    contactText: {
      fontSize: "16px"
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.image}>
        <img src={BricadoLogo} alt="About Us" style={{ width: "100%" }} />
      </div>
      <div style={styles.content}>
        <div style={styles.heading}>About</div>
        <div style={styles.paragraph}>
          <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            Welcome to <strong>Bricado</strong>, where quality meets excellence.
            At Bricado, we pride ourselves on offering an unparalleled
            e-commerce experience. Our mission is to provide our customers with
            high-quality products that meet their needs and exceed their
            expectations.
          </p>

          <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            Our team is dedicated to sourcing the best products from around the
            world, ensuring that every item we offer is of the highest standard.
            We believe in building long-lasting relationships with our
            customers, and our commitment to excellence is reflected in every
            aspect of our business.
          </p>
          {/* <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            Thank you for choosing Bricado. We look forward to serving you and
            helping you discover the best in quality and value.
          </p> */}
        </div>
        <div style={styles.contact}>
          <div style={styles.icon}>
            <img src={CallLogo} style={{ width: 20, height: 20 }} />
          </div>
          <div style={styles.contactText}> +91 7025 5751 12 </div>
        </div>
        <div style={styles.contact}>
          <div style={styles.icon}>
            <img src={MailLogo} style={{ width: 20, height: 20 }} />
          </div>
          <div style={styles.contactText}>info.bricado@gmail.com</div>
        </div>
        <div style={styles.contact}>
          <div style={styles.icon}>
            <img
              src={InstaLogo}
              style={{ width: 22, height: 22, objectFit: "contain" }}
            />
          </div>
          <div style={styles.contactText}>bricado_official</div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
