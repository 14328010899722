import React from 'react'
import CategoryBar from '../../components/categoryBar/CategoryBar'
import Footer from '../../components/footer/Footer'
import NavBar from '../../components/navbar/NavBar'
import Banner2 from './sections/banner/Banner2'
import BestSelling from './sections/bestSelling/BestSelling'
import CustomerChoices from './sections/customerChoices/CustomerChoices'
import Landing from './sections/landing/Landing'
import NewArrivals from './sections/newArrivals/NewArrivals'
import PopularBrands from './sections/popularBrands/PopularBrands'

function Home() {
    return (
        <div>
            {/* <NavBar /> */}
            {/* <CategoryBar /> */}
            <Landing />
            {/* <BestSelling /> */}
            {/* <Banner2 /> */}
            {/* <NewArrivals /> */}
            {/* <PopularBrands /> */}
            <CustomerChoices />
            {/* <Footer /> */}
        </div>
    )
}

export default Home