import React from 'react'
import Filter from './components/filter/Filter'
import List from './components/list/List'
import './productLists.css'
function ProductLists() {
    return (
        <div
            className='product_lists'
        >
            <h1 className="title2">Search result for ‘Macbook Pro’</h1>
            <div className="sub">
                <div className="filter_wrpr">
                    <Filter />

                </div>
                <div className="list_wrpr">
                    <List />

                </div>
            </div>
        </div>
    )
}

export default ProductLists