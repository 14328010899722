import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
import Cart from "../pages/cart/Cart";
import CustomerInf from "../pages/customerInf/CustomerInf";
import Home from "../pages/home/Home";
import ProductDetails from "../pages/productDetails/ProductDetails";
import ProductLists from "../pages/productLists/ProductLists";
import Success from "../pages/success/Success";
import About from "../pages/about/About";
import HelpCenter from "../pages/helpCenter/HelpCenter";

function Router() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/productLists" element={<ProductLists />} /> */}
        <Route path="/product/:id" element={<ProductDetails />} />
        {/* <Route path="/cart" element={<Cart />} /> */}
        {/* <Route path="/customer_inf" element={<CustomerInf />} /> */}
        {/* <Route path="/success" element={<Success />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<HelpCenter />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Router;
