import React from "react";
import "./footer.css";
import FbIco from "../../assets/icons/facebook.png";
import WtspIco from "../../assets/icons/whatsapp.png";
import YtIco from "../../assets/icons/youtube.png";
import Logo from "../../assets/images/logos/bricado_logo_white.png";
import { useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="sub_1">
        <img src={Logo} className="logo" />
      </div>
      <p className="desc">
        {" "}
        A leading store offering superior quality products and a wholesome{" "}
        <br />
        shopping experience to our esteemed customers.
      </p>
      <div className="tags">
        {/* <a className='desc' href="" style={{ color: 'var(--primary-color)' }}>Categories</a> */}
        <a className="desc" href="/help">
          Help Center
        </a>
        <a className="desc" href="/about">
          About us
        </a>
      </div>
      <div className="line" />
      <div className="btm_wrpr">
        <p>© 2024 www.bricado.com</p>
        <div>
          <a className="desc" style={{ color: "var(--primary-color)" }}>
            Follow Us
          </a>
          <img src={FbIco} />
          <img src={WtspIco} />
          <img src={YtIco} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
